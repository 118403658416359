import { ChangeDetectionStrategy, Component, OnChanges, OnInit } from '@angular/core';
import {
  MovieService
} from '../service/movies.service';
import { Router } from '@angular/router';
interface Domain {
  name: string;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HeaderComponent implements OnInit {
  categories: Array<any> = [];
  keyword: string = '';
  constructor(
    private _movieService: MovieService,
    private router:Router
  ) {
  }

  ngOnInit() {
    this.initData();
  }

  async initData() {
    const res: any = await this._movieService.getDomainInfo();
    if (res && res.data) {
      const data = res.data;
      this.categories = data.categories;
    }
  }
  async onChangeKeyword() {
    if(this.keyword && this.keyword.length>0){
      this.router.navigate(['/search',this.keyword])
    }else{
      this.router.navigate(['/search',''])

    }
  }
}
