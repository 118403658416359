<footer class="footer has-background-grey-lightest mt-6 py-5 px-5">
    <div class="has-text-centered">
        <div class="level">
            <a [routerLink]="['/pornstars']" class="level-item">Pornstars</a>
            <a [routerLink]="['/legal/compliance']" class="level-item">Section 2257 Compliance Statement</a>
            <a href="https://teen16x.com/" class="level-item" rel="nofollow" target="_blank">Teen16x.com</a>
            <a href="http://cumshotxx.com/" class="level-item"  target="_blank">Cumshotxx.com</a>
            <a href="http://porn69.vip/" class="level-item"  target="_blank">Porn69.com</a>
        </div>
        <div class="protection is-flex">
        </div>
        <p class="copyright has-text-grey">© 2021 - Cumshotxx.com, All Rights Reserved</p>
    </div>
</footer>