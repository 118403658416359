<header id="header">
    <!-- <section
        class="is-flex is-hidden-touch container is-size-6-desktop is-size-5-widescreen py-1 pornnetwork-container">
        <span class="network-label mr-3">Porn Network:</span>
        <a href="https://www.gotporn.com" target="_blank" rel="" class="mr-5"><strong
                class="has-text-grey">GotPorn</strong><i class="has-text-grey-light"> - Free Porn Vids</i></a>
        <a href="https://www.pornrox.com" target="_blank" rel="" class="mr-5"><strong
                class="has-text-grey is-light">Pornrox</strong><i class="has-text-grey-light"> - Amateur Porn
                Tube</i></a>
        <a href="https://www.pinflix.com" target="_blank" rel="" class="mr-5"><strong
                class="has-text-grey is-light">Pinflix</strong><i class="has-text-grey-light"> - Best Sex
                Videos</i></a>
        <a href="https://www.gethdporn.com" target="_blank" rel="" class="mr-5"><strong
                class="has-text-grey is-light">GetHDPorn</strong><i class="has-text-grey-light"> - HD Porn
                Search</i></a>
        <a href="https://www.metaporn.com" target="_blank" rel="" class="mr-5"><strong
                class="has-text-grey is-light">Metaporn</strong><i class="has-text-grey-light"> - Porn Video
                Search</i></a>
    </section> -->
    <nav class="navbar is-transparent has-background-grey-lightest" role="navigation" aria-label="main navigation">
        <div class="container py-1">
            <div class="navbar-brand">
                <div class="navbar-item is-flex is-expanded has-text-centered">
                    <a class="brand has-text-centered"  routerLink="/" title="HD Porn Videos">Cumshotxx - Watch and
                        download free HD Porn Videos</a>
                </div>
                <form class="field has-addons navbar-item mb-0 is-expanded form-search-mobile" id="header-search-form-mobile">
                    <div class="control is-expanded">
                        <input type="text" class="input is-large" id="search_query" name="search"
                            placeholder='Search for ...' autocomplete="off" maxlength="100"
                            aria-label="Search the Cumshotxx experience" [(ngModel)]="keyword"
                            (change)="onChangeKeyword()">
                    </div>
                    <div class="control">
                        <button type="submit" class="button is-large header-search-submit"
                            aria-label="Search the Cumshotxx experience"></button>
                    </div>
                </form>
            </div>
            <nav class="navbar-menu is-size-6 is-size-4-widescreen has-text-weight-semibold has-text-grey is-light">
                <form class="field has-addons navbar-item mb-0 is-expanded" id="header-search-form">
                    <div class="control is-expanded">
                        <input type="text" class="input is-large" id="search_query" name="search"
                            placeholder='Search for ...' autocomplete="off" maxlength="100"
                            aria-label="Search the Cumshotxx experience" [(ngModel)]="keyword"
                            (change)="onChangeKeyword()">
                    </div>
                    <div class="control">
                        <button type="submit" class="button is-large header-search-submit"
                            aria-label="Search the Cumshotxx experience"></button>
                    </div>
                </form>
                <a *ngFor="let category of categories" class="navbar-item"
                    [routerLink]="['/category',category.code]">{{category.name}}</a>
                <a class="navbar-item" [routerLink]="['/pornstars']">Pornstars</a>
                <!-- <a class="navbar-item">Tags</a> -->
            </nav>
        </div>
    </nav>

</header>